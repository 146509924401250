import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { theme } from '../components/AppClassic/theme/appclassic';
import Layout from "../components/Layout";
import {ThemeProvider} from "styled-components";
import {ResetCSS} from "../components/AppClassic/common/assets/css/style";
import GlobalStyle, {AppWrapper, ContentWrapper} from "../components/AppClassic/appClassic.style";
import Sticky from "react-stickynode";
import Navbar from "../components/AppClassic/Navbar";
import Banner from "../components/AppClassic/Banner";
import Customer from "../components/AppClassic/Customer";
import KeyFeatures from "../components/AppClassic/KeyFeatures";
import AppSlider from "../components/AppClassic/AppSlider";
import DesignedAndBuilt from "../components/AppClassic/DesignedAndBuilt";
import Testimonial from "../components/AppClassic/Testimonial";
import Faq from "../components/AppClassic/Faq";
import DeleteDataFormSection from "../components/AppClassic/DeleteDataForm";
import Footer from "../components/AppClassic/Footer";

// eslint-disable-next-line
export const DeletePageTemplate = ({
  client,
  testimonial,
  banner,
  keyFeatures,
  appSlider,
  designAndBuilt,
  faq,
  footer,
  navbar,
}) => {
  return (
      <ThemeProvider theme={theme}>
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <ContentWrapper>
              <DeleteDataFormSection />
            </ContentWrapper>
          </AppWrapper>
      </ThemeProvider>
  );
};

DeletePageTemplate.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const DeletePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout
        seo={{...frontmatter.seo, isIndex: true}}
    >
      <DeletePageTemplate
        client={frontmatter.client}
        testimonial={frontmatter.testimonial}
        banner={frontmatter.banner}
        keyFeatures={frontmatter.keyFeatures}
        appSlider={frontmatter.appSlider}
        designAndBuilt={frontmatter.designAndBuilt}
        faq={frontmatter.faq}
        footer={frontmatter.footer}
        navbar={frontmatter.navbar}
      />
    </Layout>
  );
};

DeletePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DeletePage;

export const pageQuery = graphql`
  query DeletePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "delete-page" } }) {
      frontmatter {
        banner {
          image { 
             childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED  
                  quality: 100
                  layout: CONSTRAINED
                  height: 700
                )
              }
          }
        }
        client {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
            }
          }
        }
        keyFeatures {
          slogan
          title
          features {
            id
            color
            icon {
              publicURL
            }
            title
            description
          }
        }
        appSlider {
          title
          description
          carousel {
            id
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                )
              }
            }
            title
          }
          features {
            id
            icon
            title
            description
          }
        }
        designAndBuilt {
          image{
            childImageSharp {
                gatsbyImageData(width: 794, quality: 100, layout: CONSTRAINED)
              }
          }
          title
          description
        }
        testimonial{
          slogan
          title
          reviews {
            id
            title
            description
            name
            designation
            review
            avatar {
              childImageSharp {
                gatsbyImageData(width: 80, quality: 80, layout: CONSTRAINED)
              }
            }
          }
        }
        faq {
          slogan
          title
          faqs{
            id
            question
            answer
          }
        }
        footer {
          logo {
            publicURL
          }
          widgets {
            id
            icon {
              publicURL
            }
            title
            link
          }
          menu {
            id
            text
            link
          }
        }
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
            staticLink
          }
        }
        seo {
          description
          image {
            relativePath
          }
        }
      }
    }
  }
`;
